import { Injectable } from "@angular/core";
import { MsalService, MsalBroadcastService } from "@azure/msal-angular";
import { HttpClient } from "@angular/common/http";
import { environment } from "@environments/environment";
import { Observable, from, throwError, EMPTY } from "rxjs";
import { catchError, map, filter } from "rxjs/operators";
import {
  EventMessage,
  EventType,
  AuthenticationResult,
} from "@azure/msal-browser";

/**
 * Service handling all authentication-related functionality using MSAL.
 * Manages user authentication state, token acquisition, and account selection.
 * Automatically handles token refresh and account management across the application.
 */
@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private http: HttpClient,
  ) {
    // Setup account management
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.LOGIN_SUCCESS ||
            msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS,
        ),
      )
      .subscribe((result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        const currentAccount = this.msalService.instance.getActiveAccount();

        // Only update if the account has changed
        if (
          !currentAccount ||
          currentAccount.homeAccountId !== payload.account.homeAccountId
        ) {
          this.msalService.instance.setActiveAccount(payload.account);
        }
      });
  }

  /**
   * Validates the current user's authentication status by making an API call.
   * If the call fails with 401, triggers a redirect to login.
   * @returns Observable<boolean> True if user is valid, throws error otherwise
   */
  validateUser(): Observable<boolean> {
    return this.http.get<any>(`${environment.apiUrl}/v1/users/me`).pipe(
      map(() => true),
      catchError((error) => {
        if (error.status === 401) {
          this.handleUnauthorized();
        }
        return throwError(() => error);
      }),
    );
  }

  /**
   * Handles unauthorized access by redirecting to MSAL login.
   * Forces account selection to ensure the correct account is used.
   */
  handleUnauthorized() {
    this.msalService.loginRedirect({
      scopes: ["api://cufi.admin.api/user_access"],
      prompt: "select_account",
    });
  }

  /**
   * Handles forbidden access (403) errors.
   * @returns Observable<never> An error observable with a permission denied message
   */
  handleForbidden() {
    return throwError(
      () => "You do not have the required permission to access this resource.",
    );
  }

  /**
   * Checks if the current user's email is from the CUFI domain.
   * @returns boolean True if user's email ends with @cufi.org, false otherwise
   */
  isUserFromCufiDomain(): boolean {
    const account = this.msalService.instance.getActiveAccount();
    return account?.username?.toLowerCase().endsWith("@cufi.org") ?? false;
  }

  /**
   * Gets an access token for API authentication.
   * First checks the MSAL cache in localStorage for a valid token.
   * If token is expired but refresh token is valid, automatically refreshes without user interaction.
   * If no valid token/refresh token, redirects to login.
   * @returns Observable<string> The access token, or EMPTY if authentication is required
   */
  getAccessToken(): Observable<string> {
    const account = this.msalService.instance.getActiveAccount();

    if (!account) {
      this.handleUnauthorized();
      return EMPTY;
    }

    return from(
      this.msalService.instance.acquireTokenSilent({
        scopes: ["api://cufi.admin.api/user_access"],
        account: account,
      }),
    ).pipe(
      map((response) => response.accessToken),
      catchError((error) => {
        console.error("Token acquisition failed:", error);
        this.handleUnauthorized();
        return EMPTY;
      }),
    );
  }
}
