import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './layouts/layout.component';
import { PubliclayoutComponent } from './publiclayout/publiclayout.component';
import {MsalGuard, MsalRedirectComponent} from "@azure/msal-angular";
import {customAuthGuard} from "@app/core/guards/custom-auth.guard";



const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),
    canActivate: [MsalGuard]
  },
  {
    path: 'auth',
    component: PubliclayoutComponent,
    loadChildren: () => import('./public/public.module').then(m => m.PublicModule)
  },
  // { path: 'auth-callback', component: MsalRedirectComponent }, // Handles Azure AD responses
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
