import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {BehaviorSubject, EMPTY, lastValueFrom} from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import {NGXLogger} from "ngx-logger";
import { environment } from '@environments/environment';

@Injectable({
    providedIn: 'root',
})
export class EnvironmentService {
    private static readonly _configFileUrlOrLocation = 'assets/config.json';
    private readonly _environment: BehaviorSubject<any>;

    constructor(private http: HttpClient, private logger: NGXLogger) {
        this._environment = new BehaviorSubject<any>(environment);
    }

    public get environment(): BehaviorSubject<any> {
        return this._environment;
    }

    public loadEnvironment(): Promise<any> {
        return lastValueFrom(
            this.http.get(EnvironmentService._configFileUrlOrLocation).pipe(
                tap((response) => {
                    this.logger.debug('Loading config: ', response);
                    const env = environment;

                    // Save the config to environment vars
                    Object.keys(response).forEach((key) => {
                        if (key in env) {
                            // If key already exists, update it                            
                            env[key] = response[key];
                        }
                    });
                    console.log('\n\n' +
                        '\tCommit: ' + env.commitHash +'\n' +
                        '\tUpdate Date: ' + env.commitDate +'\n' +
                        '\tDeployment Date: ' + env.deployDate +'\n' +
                        '\n\n',
                    );
                    this._environment.next(env);
                }),

                // No config file found error
                catchError(_ => EMPTY),
            ),
        );
    }

    getApiUrl(): string {
        // Access the current value of the BehaviorSubject
        const env = this._environment.value;

        // Return the `apiUrl` property or a default value if it doesn't exist
        return env?.apiUrl ?? '';
    }
}
