import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { AuthService } from "../services/auth.service";
import { ToastrService } from "ngx-toastr";
import { LoadingService } from "@app/core/services/loading.service";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private toastr: ToastrService,
    private loader: LoadingService,
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    this.loader.stop();

    return next.handle(request).pipe(
      catchError((err) => {
        if (err.status === 401) {
          // User is not authenticated or token is invalid
          this.authService.handleUnauthorized();
        }
        if (err.status === 403) {
          // User is authenticated but doesn't have permission
          this.toastr.error(
            "You do not have permission to perform this action",
            "Access Denied",
          );
        }

        const error = err?.message || err?.error?.message || err?.statusText;
        return throwError(() => error || "Unknown Error");
      }),
    );
  }
}
