import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

// Page Route
import {AppRoutingModule} from './app-routing.module';
import {LayoutsModule} from './layouts/layouts.module';
import {ToastrModule} from 'ngx-toastr';

// Store
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
// component
import {AppComponent} from './app.component';
import {PubliclayoutComponent} from './publiclayout/publiclayout.component';
import {AnalyticsEffects} from './store/Analytics/analytics.effects';
import {rootReducer} from './store';
import {ErrorInterceptor} from './core/helpers/error.interceptor';
import {LoadingService} from './core/services/loading.service';
import {CRMEffects} from './store/CRM/crm.effects';
import {ECoEffects} from './store/Ecommerce/ecommerce.effects';
import {LearningEffects} from './store/Learning/learning.effects';
import {RealEffects} from './store/RealEstate/realEstate.effects';
import {AppRealestateEffects} from './store/App-realestate/apprealestate.effects';
import {AgentEffects} from './store/Agent/agent.effects';
import {AgenciesEffects} from './store/Agency/agency.effects';
import {TicketEffects} from './store/Tickets/ticket.effects';
import {ChatEffects} from './store/chat/chat.effects';
import {ProductEffects} from './store/Product/product.effect';
import {InvoiceEffects} from './store/Invoices/invoices.effects';
import {SellerEffects} from './store/Seller/seller.effects';
import {OrdersEffects} from './store/Orders/order.effects';
import {InstructorEffects} from './store/Learning-instructor/instructor.effects';
import {CustomerEffects} from './store/Customer/customer.effects';
import {studentsEffects} from './store/students/student.effcts';
import {CourcesEffects} from './store/Learning-cources/cources.effect';
import {GlobalErrorHandler} from './errorHandler';
import {EnvironmentService} from "@environments/environment.service";
import {CoreModule} from "@app/core/utils/core.module";
import {SharedModule} from "@app/shared/shared.module";
import {LoggerModule, NgxLoggerLevel} from "ngx-logger";
import {environment} from "@environments/environment";

import {
    MsalModule,
    MsalService,
    MsalGuard,
    MsalInterceptor,
    MsalBroadcastService,
    MsalRedirectComponent,
} from "@azure/msal-angular";
import {PublicClientApplication, InteractionType, BrowserCacheLocation} from "@azure/msal-browser";


const baseUrl = window.location.origin;
const isIE = window.navigator.userAgent.includes('MSIE') || window.navigator.userAgent.includes('Trident/');

@NgModule({
    declarations: [
        AppComponent,
        PubliclayoutComponent
    ],
    imports: [
        StoreModule.forRoot(rootReducer),
        StoreDevtoolsModule.instrument({
            maxAge: 25, // Retains last 25 states
            logOnly: environment.production, // Restrict extension to log-only mode
        }),

        LoggerModule.forRoot({
            level: environment.production ? NgxLoggerLevel.ERROR : NgxLoggerLevel.DEBUG,
        }),
        CoreModule,
        SharedModule,


        EffectsModule.forRoot([
            AnalyticsEffects,
            CRMEffects,
            ECoEffects,
            LearningEffects,
            RealEffects,
            AppRealestateEffects,
            AgentEffects,
            AgenciesEffects,
            TicketEffects,
            ChatEffects,
            ProductEffects,
            InvoiceEffects,
            SellerEffects,
            OrdersEffects,
            InstructorEffects,
            CustomerEffects,
            studentsEffects,
            CourcesEffects,
            InstructorEffects
        ]),
        HttpClientModule,
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        LayoutsModule,
        ToastrModule.forRoot({
            timeOut: 5000,
            positionClass: 'toast-top-right',
            preventDuplicates: true,
            closeButton: true
        }),
        FormsModule,
        ReactiveFormsModule,
        MsalModule.forRoot(new PublicClientApplication({ // MSAL Configuration
                auth: {
                    clientId: "e7518082-1705-44a3-99e0-53b9d05fa95a",
                    authority: "https://login.microsoftonline.com/123aa0b4-9ae0-40d1-b8ae-87c26edbe766/v2.0",
                    redirectUri: `${baseUrl}`,
                    postLogoutRedirectUri: `${baseUrl}/auth/logout`,
                },
                cache: {
                    cacheLocation: BrowserCacheLocation.LocalStorage,
                    storeAuthStateInCookie: isIE, // set to true for IE 11
                },
                system: {
                    loggerOptions: {
                        loggerCallback: () => {
                        },
                        piiLoggingEnabled: false
                    }
                }
            }),
            {
                interactionType: InteractionType.Redirect // MSAL Guard Configuration
            },
            {
                interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
                protectedResourceMap: new Map([
                    ['https://graph.microsoft.com/v1.0/me', ['user.read']],
                    ["http://localhost:8080/*", ['api://cufi.admin.api/user_access']],
                    ["https://localhost:8081/*", ['api://cufi.admin.api/user_access']],
                    ["https://dev-api.cufi.org/*", ['api://cufi.admin.api/user_access']],
                    ["https://staging-api.cufi.org/*", ['api://cufi.admin.api/user_access']],
                    ["https://forms-api.cufi.org/*", ['api://cufi.admin.api/user_access']],
                ])
            })
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: (envService: EnvironmentService) => () => Promise.all<any>([envService.loadEnvironment()]),
            deps: [EnvironmentService],
            multi: true,
        },
        // {
        //     provide: MSAL_INSTANCE,
        //     useFactory: MSALInstanceFactory,
        //     deps: [EnvironmentService], // Inject EnvironmentService here
        // },
        // {
        //     provide: MSAL_GUARD_CONFIG,
        //     useValue: {
        //         interactionType: InteractionType.Redirect,
        //         authRequest: {
        //             scopes: ['api://cufi.admin.api/user_access']
        //         },
        //         loginFailedRoute: '/auth/login'
        //     },
        // },
        // {
        //     provide: MSAL_INTERCEPTOR_CONFIG,
        //     useFactory: MSALInterceptorConfigFactory,
        //     deps: [EnvironmentService], // Inject EnvironmentService here
        //    
        // },
        
        {provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
        // {provide: ErrorHandler, useClass: GlobalErrorHandler},
        LoadingService,
        MsalService,
        MsalGuard,
        MsalBroadcastService,
    ],
    bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule {
}
